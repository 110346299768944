<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item label="选择时间：">
				<el-date-picker v-model="filters.timeRange" type="daterange" align="right" unlink-panels
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
				</el-date-picker>
			</el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getTotal">查询</el-button>
            </el-form-item>
			<el-form-item>
				<el-button type="primary" @click="exportExcel">创建Excel导出任务</el-button>
			</el-form-item>
        </el-form>
        <div class="type-filters">
            <div class="filter-items">
                <span>系统总收入：￥{{income.totalFee}}</span>
                <div class="filter-item" v-for="(item, index) in income.types" :class="filters.type === item.type ? 'sel' : ''" :key="index" @click="handleClickType(item.type)">
                    <div>{{item.name}}</div>
                    <div>￥{{item.value}}</div>
                </div>
            </div>
            <div class="filter-items">
                <span>系统应结算：￥{{shouldOut.totalFee}}</span>
                <div class="filter-item" v-for="(item, index) in shouldOut.type" :class="filters.type === item.type ? 'sel' : ''" :key="index" @click="handleClickType(item.type)">
                    <div>{{item.name}}</div>
                    <div>￥{{item.value || 0}}</div>
                </div>
            </div>
        </div>
        <el-table v-if="dataList.length" :data="dataList" border style="width: 100%;">
            <el-table-column prop="orderId" header-align="center" align="left" label="订单号"></el-table-column>
            <!-- <el-table-column prop="bizOrderId" header-align="center" align="left" label="业务单号"></el-table-column>
            <el-table-column prop="transactionId" header-align="center" align="left" label="微信订单号"></el-table-column> -->
            <!-- <el-table-column prop="bizType" header-align="center" align="left" label="业务类型"></el-table-column> -->
            <el-table-column prop="type" header-align="center" align="left" label="费用类型"></el-table-column>
            <!-- <el-table-column prop="body" header-align="center" align="left" label="费用名称"></el-table-column> -->
            <el-table-column prop="totalFee" header-align="center" align="left" label="金额"></el-table-column>
            <!-- <el-table-column prop="refundFee" header-align="center" align="left" label="退款金额"></el-table-column> -->
            <el-table-column prop="createdAt" header-align="center" align="left" label="创建时间"></el-table-column>
        </el-table>
		<div style="float: right; margin-top: 20px;" v-if="dataList.length">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			form: {},
			formLabelWidth: '120px',
			filters: {
				type: '',
				timeRange: ''
			},
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			income: {
				totalFee: 0,
				types: []
			},
			shouldOut: {
				totalFee: 0,
				types: []
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.getTotal();
	},
	methods: {
		getTotal () {
			if (!this.filters.timeRange) this.filters.timeRange = ['', ''];
			this.$root.request('incomeOrCashOut', {
				startTime: this.filters.timeRange[0],
				endTime: this.filters.timeRange[1]
			}).then((data) => {
				if (data) {
					this.income = { ...data.income };
					this.shouldOut = { ...data.shouldOut };
					if (this.filters.type) this.getData();
				}
			});
		},
		exportExcel () {
			this.$prompt('请输入Excel文件名', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(({ value }) => {
				this.$root.request('exportExcelTask', {
					name: value,
					apiName: '/mnt/incomeOrCashOut/detail',
					headerModel: [
						{ header: '订单号', key: 'orderId', width: 27 },
						{ header: '费用类型', key: 'type', width: 18 },
						{ header: '金额', key: 'totalFee', width: 18 },
						{ header: '创建时间', key: 'createdAt', width: 30 }
					],
					dataFormatMap: {},
					options: {
						type: this.filters.type,
						startTime: this.filters.timeRange[0],
						endTime: this.filters.timeRange[1]
					}
				}).then(data => {
					if (data && data.ok === 1) {
						this.$root.elMsg('操作成功');
					}
				});
			});
		},
		handleClickType (type) {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			if (this.filters.type !== type) {
				this.filters.type = type;
				this.getData();
			} else {
				this.filters.type = '';
				this.dataList = [];
				this.total = 0;
			}
		},
		getData () {
			this.$root.request('incomeOrCashOutDetail', {
				...this.filters,
				startTime: this.filters.timeRange[0],
				endTime: this.filters.timeRange[1],
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows.map(r => {
						r.totalFee = '￥' + (r.costs || r.totalFee);
						return r;
					});
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		}
	}
};
</script>

<style lang="less" scoped>
	.service-cell {
		display: flex;
		align-items: center;

		div {
			text-align: left;
			margin-left: 10px;
		}

		.doctor-avatar {
			width: 100px;
			height: 100px;
		}
	}

	.click-to-show {
		cursor: pointer;
		color: deepskyblue;
    }
    .type-filters {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .filter-items {
        display: flex;
        align-items: center;
        margin: 5px 0;
        span {
            min-width: 280px;
            font-size: 24px;
        }
        .filter-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid #dedede;
            border-radius: 5px;
            width: 200px;
            padding: 10px;
            margin: 0 5px;
            cursor: pointer;
            div:last-child {
                font-size: 20px;
                margin-top: 7px;
            }
        }
        .filter-item:hover {
						background: #66b1ff;
						transition: 0.5s ease;
						color: #ffffff;
        }
        .filter-item.sel {
            background: #409EFF;
            color: #ffffff;
        }
    }
</style>